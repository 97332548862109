// Packages
import PropTypes from 'prop-types';

const AdminIcon = ({
                        colour = "currentColor",
                        height = "25px",
                        onClick = () => {},
                        styles = {},
                        width = "25px", 
                    }) => {
    
    return(
        <div onClick={onClick} style={{display: 'inline-block', height: height, ...styles}}>
            <svg fill={colour} height={height} width={width} enableBackground="new 0 0 25 25"  viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="m23.7 9.9-1.6-.3c-.2-.6-.4-1.2-.8-1.8l1-1.3c.3-.4.2-.9-.1-1.3l-2.3-2.3c-.3-.3-.9-.4-1.3-.1l-1.3.9c-.6-.3-1.3-.6-2-.8l-.3-1.6c-.1-.5-.5-.8-1-.8h-3.3c-.5 0-.9.4-1 .8l-.2 1.6c-.6.1-1.3.4-1.9.8l-1.2-.9c-.4-.3-.9-.3-1.3.1l-2.3 2.3c-.3.3-.4.9-.1 1.3l.9 1.2c-.4.6-.6 1.3-.8 2l-1.5.2c-.5.1-.8.5-.8 1v3.3c0 .5.4.9.8 1l1.5.2c.2.7.5 1.3.8 2l-.8 1.2c-.3.4-.3.9.1 1.3l2.3 2.3c.3.3.9.4 1.3.1l1.2-.9c.6.3 1.2.6 1.8.8l.2 1.5c.1.5.5.8 1 .8h3.3c.5 0 .9-.4 1-.8l.2-1.5c.7-.2 1.3-.5 1.9-.8l1.3.9c.4.3 1 .2 1.3-.1l2.3-2.3c.3-.3.4-.9.1-1.3l-.9-1.3c.3-.6.6-1.2.8-1.8l1.6-.3c.5-.1.8-.5.8-1v-3.3c.1-.5-.3-.9-.7-1zm-5.4 7.3c-.9-1.1-2-1.9-3.4-2.4-.2-.1-.4 0-.5 0-.6.2-1.2.4-1.8.4s-1.3-.1-1.8-.4c-.2-.1-.4-.1-.5 0-1.4.5-2.6 1.3-3.4 2.4-1-1.3-1.6-2.9-1.6-4.6 0-4.1 3.3-7.5 7.5-7.5 4.1 0 7.4 3.3 7.4 7.5-.3 1.7-.9 3.3-1.9 4.6z"></path>
                    <path d="m12.5 7.6c-1.7 0-3 1.4-3 3 0 1.7 1.3 3 3 3 1.6 0 3-1.3 3-3s-1.3-3-3-3z"></path>
                </g>
            </svg>
        </div>
    )
}

AdminIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    styles: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default AdminIcon;