// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const BasketIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="BasketIcon" onClick={onClick} style={style}>
            <svg height={height} width={width} viewBox="0 0 25 25" version="1.1">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Header" transform="translate(-1315, -65)" fill={colour} fillRule="nonzero">
                        <g id="shopping-bag-2" transform="translate(1315, 65)">
                            <polygon id="Path" points="10.9833333 6.58333333 14.0166667 6.58333333 14.0166667 8.00833333 10.9833333 8.00833333"></polygon>
                            <polygon id="Path" points="14.0166667 13.2083333 10.9833333 13.2083333 10.9833333 10.6083333 9.56666667 10.6083333 9.56666667 14.6333333 15.4333333 14.6333333 15.4333333 10.6083333 14.0166667 10.6083333"></polygon>
                            <path d="M12.5,0 C5.59644063,0 0,5.59644063 0,12.5 C0,19.4035594 5.59644063,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C24.9975026,5.59747533 19.4025247,0.00249735811 12.5,0 L12.5,0 Z M5,19.8333333 L7.45833333,8.00833333 L9.56666667,8.00833333 L9.56666667,5.16666667 L15.4333333,5.16666667 L15.4333333,8.00833333 L17.5416667,8.00833333 L20,19.8333333 L5,19.8333333 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

BasketIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BasketIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default BasketIcon;