// Packages
import { useParams } from 'react-router-dom';

// Components
import MicrositeSectionList from 'components/Event/Sections/List';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const EventViewScreen = () => {

    const { eventId } = useParams();

    const { data: event } = useApi('/events/' + eventId + '?with=microsite');
    const { data: sections, isLoading } = useApi('/events/' + eventId + '/microsite/sections?with=template');

    return(
        <div className='EventViewScreen'>

            <MicrositeSectionList
                eventId={eventId}
                isLoading={isLoading}
                items={sections}  
                max_width={event.max_width}
                slug={event.microsite ? event.microsite.slug : ''}
                start_date={event.start_at}
            />
        </div>
    )
}

export default EventViewScreen;