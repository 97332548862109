// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';

// other
import './styles.css'
import Container from 'components/BTDUI/Container';
import FormTemplate from 'components/BTDUI/FormTemplate';

const MicrositeAccommodation = ({ items, title, }) => {

    const [selectedItem, setSelectedItem] = useState();
    const [formActive, setFormActive] = useState(false);

    return(
        <>

        <section className="MS_Section_Accommodation" >

            <Container>

                <h2 className="MS_Section_Accommodation__title white centre">{title ? title : 'Accommodation'}</h2>

                {items.length > 0 &&

                <div className='MS_Section_Accommodation__items'>

                    {items.map((item) => {
                        return(

                            <div key={item.id} className='MS_Section_Accommodationn__items__item'>
                                <div className='MS_Section_Accommodation__items__item__image' style={{backgroundImage: `url(${item.image_upload ? item.image_upload.url: ''})`}}/>
                                <h3 className='MS_Section_Accommodation__items__item__name white centre'>{item.name ?? ''}</h3>
                                <p className='MS_Section_Accommodation__items__item__text white centre'>{item.distance_from_venue ?? ''} miles from venue</p>
                                <p className='MS_Section_Accommodation__items__item__link pink centre' onClick={() => {
                                setSelectedItem(item);
                                setFormActive(true);
                            }}>View Details</p>
                            </div>
                        )
                    })}


                </div>
                }

            </Container>

        </section>

        {formActive &&
            <FormTemplate onClose={() => {setFormActive(false)}}>

                <div className='MS_Section_Accommodation__modal'>
                    <div className='MS_Section_Accommodation__modal__column'>
                        <div className='MS_Section_Accommodation__modal__column__image' style={{backgroundImage: `url(${selectedItem.image_upload ? selectedItem.image_upload.url: ''})`}}/>
                        <div className='MS_Section_Accommodation__modal__column__address'>{selectedItem.address ?? ''}</div>
                        <a href={selectedItem.address ? 'https://www.google.com/maps/place/' + encodeURIComponent(selectedItem.address) : ''} className='MS_Section_Accommodation__modal__column__link pink' target='_blank' rel="noreferrer">Map</a>
                    </div>
                    <div className='MS_Section_Accommodation__modal__column'>
                        <h3 className='MS_Section_Accommodation__modal__column__name medium black'>{selectedItem.name ?? ''}</h3>
                        <p className='MS_Section_Accommodation__modal__column__text medium black'>{selectedItem.distance_from_venue ?? ''} miles from venue</p>
                        <p className='MS_Section_Accommodation__modal__column__text medium black'>{selectedItem.telephone ?? ''}</p>
                        <a href={selectedItem.website ?? ''} className='MS_Section_Accommodation__modal__column__link pink' target='_blank' rel="noreferrer">Website</a>
                        <p className='MS_Section_Accommodation__modal__column__notes black'>{selectedItem.notes ?? ''}</p>

                    </div>
                </div>
            </FormTemplate>    
        }

        </>
    )
}

MicrositeAccommodation.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
}

MicrositeAccommodation.defaultProps = {
    items: [],
    title: '',
}

export default MicrositeAccommodation;