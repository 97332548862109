// Packages
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const EventListItem = ({ featured_image_upload, image_url, isLoading, name, start_at, venue }) => {

	const { date } = useAppData();

	return (
		<div className="EventListItem">

			<div className="EventListItem__image" style={{backgroundImage: `url(${image_url})`}}/>
			{/* <div className="EventListItem__image" style={{backgroundImage: `url(${featured_image_upload ? featured_image_upload.url : ''})`}}/> */}

			<div className='EventListItem__main'>
				
				<h3 className='black semi-bold'>{! isLoading ? name : <BTDSkeleton />}</h3>

				<p className='black medium'>{! isLoading ? date(start_at) : <BTDSkeleton /> }</p>

				<p className='black opacity medium'>{! isLoading ? venue.name : <BTDSkeleton /> }</p>

			</div>

		</div>
	)
}

EventListItem.propTypes = {
	available: PropTypes.number,
	capacity: PropTypes.number,
	isLoading: PropTypes.bool,
	sold: PropTypes.number,
	start_at: PropTypes.string,
	venue: PropTypes.object,
	name: PropTypes.string
}

EventListItem.defaultProps = {
	available: null,
	capacity: null,
	isLoading: false,
	sold: null,
	start_at: '',
	venue: {},
	name: null
}

export default EventListItem