// Packages
import ListItem from 'components/Event/Sections/ListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const MicrositeSectionList = ({ eventId, isLoading, items, max_width, slug, start_date }) => {


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='MicrositeSectionList'>
				<div
					style={{ 
						alignItems: 'center',
						display: 'flex', 
						height: '50vh',
						justifyContent: 'center'
						}}
				>
					<BTDProgress type='circular' width={100} />
				</div>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No sections to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='MicrositeSectionList' style={{maxWidth: `${max_width}px`}}>

			{items.map((item, index) => {

				return (
						<ListItem
							key={item.id} 
							eventId={eventId} 
							index={index} 
							isLoading={false} 
							section={item}
							slug={slug}
							start_date={start_date}
						/>
					)
				
			})}

		</div>
	)
}

MicrositeSectionList.propTypes = {
	eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	max_width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	slug: PropTypes.string,
	start_date: PropTypes.string
}

MicrositeSectionList.defaultProps = {
	eventId: '',
	isLoading: false,
	items: {},
	max_width: '2000',
	slug: '',
	start_date: ''
}

export default MicrositeSectionList;