// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';

// other
import './styles.css'

const MicrositeHeader = ({ eventId, image_upload, navigation, show_navigation, show_buy_tickets_button, slug }) => {

    return(
        <section className='MS_Section_Header'>

            <div className="MS_Section_Header__logo" style={{backgroundImage: `url('${image_upload ? image_upload.url : ''}')`}}></div>

            {show_navigation &&
            
                <div className="MS_Section_Header__nav">
                    {navigation.map((item) => {
                        return(
                            <Link 
                                className='MS_Section_Header__nav__item medium black'
                                key={item.id}
                                to={'section' + item.id}
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                {item.label}
                            </Link>
                        )
                    })}
                </div>
            }

            {show_buy_tickets_button && 
            
                <a href={'/s/' + eventId + '/' + slug + '/tickets'} className="MS_Section_Header__button">Buy Tickets</a>
            }

        </section>
    )
}

MicrositeHeader.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image_upload: PropTypes.object,
    navigation: PropTypes.array,
    show_navigation: PropTypes.bool,
    show_buy_tickets_button: PropTypes.bool,
    slug: PropTypes.string,
}

MicrositeHeader.defaultProps = {
    eventId: '',
    image_upload: {},
    navigation: [],
    show_navigation: true,
    show_buy_tickets_button: true,
    slug: '',
}

export default MicrositeHeader;