// Packages
import PropTypes from 'prop-types';

// other
import './styles.css'

const MicrositeText = ({ content, title, }) => {

    return(

        <section className="MS-Section-Text">

            <div className="MS-Section-Text__title">{title}</div>

            {<div className="MS-Section-Text__content" dangerouslySetInnerHTML={{ __html: content }} />}
            
        </section>
    )
}

MicrositeText.propTypes = {
    content: PropTypes.string,
    title: PropTypes.string,
}

MicrositeText.defaultProps = {
    content: '',
    title: '',
}

export default MicrositeText;