// Components
import EventList from 'components/Event/List';
import Container from 'components/BTDUI/Container';
import PersonIcon from 'components/BTDUI/icons/PersonIcon';
import BasketIcon from 'components/BTDUI/icons/BasketIcon';

// Context
import { useAppData } from "context/AppData";

// Other
import useApi from 'hooks/useApi';
import './styles.css'
import Logo from 'assets/oakora-logo.svg';

const EventsListScreen = () => {

    const { data: events, isLoading, } = useApi('/events?with=venue,featured_image,microsite');

    // const { settings, user } = useAppData();

    return(
        <div className='EventsListScreen'>

            <Container>

                <div className='EventsListScreen__header'>
                    <div className='EventsListScreen__header__link'>
                        <PersonIcon colour={'#888'} style={{marginRight: '5px'}} /> Ambassador Login
                        {/* <PersonIcon colour={settings ? settings.feature_colour : ''} style={{marginRight: '5px'}} /> Ambassador Login */}
                    </div>

                    <div className="EventListScreen__header__logo">
                        {/* <img src={Logo} alt="Logo" /> */}
                        {/* <img src={settings.image_upload ? settings.image_upload.url : user.avatar_url} alt="Logo" /> */}
                    </div>

                    <div className='EventsListScreen__header__link'>
                    Your Basket <BasketIcon colour={'#888'} style={{marginLeft: '5px'}} />
                    {/* Your Basket <BasketIcon colour={settings ? settings.feature_colour : ''} style={{marginLeft: '5px'}} /> */}
                    </div>

                </div>


                <EventList items={events} isLoading={isLoading} />

            </Container>
            
        </div>
    )

}

export default EventsListScreen;