// Packages
import ListItem from 'components/Guest/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const GuestList = props => {

	const { isLoading, items, onChange } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='GuestList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No guests to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='GuestList'>

			{items.map((item, index) => {

				return (
					<div key={item.id}>
						<ListItem {...item} key={item.id} index={index + 1} isLoading={false} onChange={onChange}/>
					</div>
				)
				
			})}
	
		</div>
	)
}

GuestList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func
}

GuestList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {alert('onChange is not set!')}
}

export default GuestList;