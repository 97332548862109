// Packages
import PropTypes from 'prop-types';

const UserIcon = ({ 
                    colour = "currentColor", 
                    height = "17px", 
                    onClick = () => {}, 
                    styles = {}, 
                    width =  "17px"
                }) => {
    
    return(
        <div onClick={onClick} style={{display: 'inline-block', ...styles}}>
            <svg height={height} width={width}  viewBox="0 0 25 24.9996332" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Tenant-Bar" transform="translate(-1475, -13)" fill={colour} fillRule="nonzero">
                        <g id="account" transform="translate(1475, 13)">
                            <path d="M12.5089504,13.3335411 C9.74766397,13.3335411 7.50919905,11.0950762 7.50919905,8.3337898 C7.50919905,5.57250339 9.74766397,3.33403847 12.5089504,3.33403847 C15.2702368,3.33403847 17.5087017,5.57250339 17.5087017,8.3337898 C17.5087017,11.0950762 15.2702368,13.3335411 12.5089504,13.3335411 L12.5089504,13.3335411 Z M12.5089504,5.00062225 C10.6680928,5.00062225 9.17578283,6.49293219 9.17578283,8.3337898 C9.17578283,10.1746474 10.6680928,11.6669573 12.5089504,11.6669573 C14.349808,11.6669573 15.8421179,10.1746474 15.8421179,8.3337898 C15.8421179,6.49293219 14.349808,5.00062225 12.5089504,5.00062225 L12.5089504,5.00062225 Z" id="Shape"></path>
                            <path d="M12.5089504,24.9996276 C8.76720198,24.9971335 5.22336558,23.3184741 2.8510974,20.4248551 L2.41778561,19.8915483 L2.8510974,19.3665744 C5.22533738,16.4767484 8.76888222,14.8019509 12.5089504,14.8019509 C16.2490185,14.8019509 19.7925634,16.4767484 22.1668034,19.3665744 L22.6001151,19.8915483 L22.1668034,20.4248551 C19.7945352,23.3184741 16.2506988,24.9971335 12.5089504,24.9996276 L12.5089504,24.9996276 Z M4.60101036,19.8998812 C6.64961626,22.0929727 9.51621077,23.3379438 12.5172833,23.3379438 C15.5183558,23.3379438 18.3849503,22.0929727 20.4335562,19.8998812 C18.3849503,17.7067897 15.5183558,16.4618186 12.5172833,16.4618186 C9.51621077,16.4618186 6.64961626,17.7067897 4.60101036,19.8998812 L4.60101036,19.8998812 Z" id="Shape"></path>
                            <path d="M12.5089504,24.9996304 C6.6607361,25.0035258 1.59159835,20.951841 0.306668677,15.2465294 C-0.978260996,9.54121773 1.86455189,3.70763209 7.1498056,1.20409086 C12.4350593,-1.29945036 18.7497061,0.196391196 22.3502407,4.80483738 C25.9507753,9.41328356 25.8745707,15.9022357 22.1668034,20.4248551 C19.7945352,23.3184741 16.2506988,24.9971335 12.5089504,24.9996304 L12.5089504,24.9996304 Z M12.5089504,1.66745469 C6.52616315,1.66745469 1.67615583,6.51746201 1.67615583,12.5002492 C1.67615583,18.4830365 6.52616315,23.3330438 12.5089504,23.3330438 C18.4917376,23.3330438 23.3417449,18.4830365 23.3417449,12.5002492 C23.3417449,6.51746201 18.4917376,1.66745469 12.5089504,1.66745469 Z" id="Shape"></path>
                            <path d="M3.49273215,19.8998812 C3.49273215,19.8998812 11.8839815,29.2744149 20.4252233,20.8331681 L21.5251686,19.8998812 C21.5251686,19.8998812 14.39219,12.5002492 7.15088354,16.941695 L3.49273215,19.8998812 Z" id="Path"></path>
                            <circle id="Oval" cx="12.5089504" cy="8.3337898" r="4.16645944"></circle>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

UserIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    styles: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default UserIcon;