// Packages
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Components
import BasketTimer from "components/BTDUI/BasketTimer";
import BTDProgress from "components/BTDUI/BTDProgress";
import BTDButton from "components/BTDUI/BTDButton";

// Context
import { useAppData } from "context/AppData";
import { useBasket } from "context/Basket";
import { useNav } from "context/Nav";
import { useUI } from "context/UI";
import { useSnackbar } from 'context/Snackbar'

// other
import './styles.css';
import useApi from "hooks/useApi";
import BTDTextField from "components/BTDUI/fields/BTDTextField";
import BTDSelectField from "components/BTDUI/fields/BTDSelectField";

const SummaryScreen = () => {

    const { settings, user } = useAppData();
    const { basket, hasBasket, getGroupedBasketItems, getBasketTotal } = useBasket();
    const { setUnsavedChanges } = useNav();
    const { getLoading } = useUI();
    const { showSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [basketUuid, setBasketUuid] = useState();
    const [data, setData] = useState({});

    // const { errors, put: updateBasket } = useApi('/baskets/' + basketUuid + '/tickets', false);
    const { errors, put: updateBasket } = useApi('/baskets/' + basketUuid, false);
    const { data: countries } = useApi('/countries');

    useEffect(() => {

        const checkBasket = async () => {
    
            if ( ! await hasBasket()) {

                navigate('/');

            } else {

                setUnsavedChanges(true);
                
                if( basket ) {
                    
                    setBasketUuid(basket.uuid);
                }
            }
        };
    
        checkBasket();

    // eslint-disable-next-line
    }, [hasBasket]);

        
    if( getLoading() ) {

        return(
            <BTDProgress type="circular" width={100} style={{margin: '100px auto'}} />
            )
    } 
    return (
        <div className="SummaryScreen">

            <BasketTimer />

            <div className="SummaryScreen__logo">
                <img src={settings.image_upload ? settings.image_upload.url : user.avatar_url} alt="Logo" />
            </div>

            <h1 className="SummaryScreen__title semi-bold">Order Summary</h1>

            {getGroupedBasketItems() &&
                
                <div className="SummaryScreen__items">

                    <div className="SummaryScreen__items__item">
                        <span className="SummaryScreen__items__item__section"></span>
                        <span className="SummaryScreen__items__item__section bold centre">Quantity</span>
                        <span className="SummaryScreen__items__item__section bold centre">Amount</span>
                    </div>

                    {getGroupedBasketItems().map((ticket, index) => {

                        return(
                            <div key={index} className="SummaryScreen__items__item">
                                <span className="SummaryScreen__items__item__section">{ticket.name}</span>
                                <span className="SummaryScreen__items__item__section centre">{ticket.quantity}</span>
                                <span className="SummaryScreen__items__item__section centre">{ticket.total}</span>
                            </div>
                        )
                    })}

                    <div className="SummaryScreen__items__item">
                        <span className="SummaryScreen__items__item__section"></span>
                        <span className="SummaryScreen__items__item__section bold centre">Total</span>
                        <span className="SummaryScreen__items__item__section bold centre">{getBasketTotal()}</span>
                    </div>

                </div>
            }

            <h3 className="SummaryScreen__subtitle semi-bold">Enter your details</h3>

            <BTDTextField 
                error={errors && errors.firstname ? errors.firstname : null} 
                label="First Name"
                onChange={(value) => {setData({...data, firstname: value})}}
                required={true}
                value={data ? data.firstname : ''}
            />

            <BTDTextField 
                error={errors && errors.lastname ? errors.lastname : null} 
                label="Last Name"
                onChange={(value) => {setData({...data, lastname: value})}}
                required={true}
                value={data ? data.lastname : ''}
            />

            <BTDTextField 
                error={errors && errors.email ? errors.email : null} 
                label="Email"
                onChange={(value) => {setData({...data, email: value})}}
                required={true}
                value={data ? data.email : ''}
            />

            <BTDTextField 
                error={errors && errors.telephone ? errors.telephone : null} 
                label="Telephone"
                onChange={(value) => {setData({...data, telephone: value})}}
                required={true}
                value={data ? data.telephone : ''}
            />

            <BTDTextField 
                error={errors && errors.organisation ? errors.organisation : null} 
                label="Organisation"
                onChange={(value) => {setData({...data, organisation: value})}}
                value={data ? data.organisation : ''}
            />

            <h3 className="SummaryScreen__subtitle semi-bold">Billing Address</h3>

            <BTDTextField 
                error={errors && errors.address1 ? errors.address1 : null} 
                label="Address line 1"
                onChange={(value) => {setData({...data, address1: value})}}
                value={data ? data.address1 : ''}
            />

            <BTDTextField 
                error={errors && errors.address2 ? errors.address2 : null} 
                label="Address line 2"
                onChange={(value) => {setData({...data, address2: value})}}
                value={data ? data.address2 : ''}
            />

            <BTDTextField 
                error={errors && errors.city ? errors.city : null} 
                label="City"
                onChange={(value) => {setData({...data, city: value})}}
                value={data ? data.city : ''}
            />

            <BTDTextField 
                error={errors && errors.county ? errors.county : null} 
                label="County"
                onChange={(value) => {setData({...data, county: value})}}
                value={data ? data.county : ''}
            />

            <BTDTextField
                error={errors && errors.postcode ? errors.postcode : null} 
                label='Postcode'
                onChange={(value) => {setData({...data, postcode: value})}} 
                value={data.postcode ?? ''} 
            />

            <BTDSelectField
                error={errors && errors.country_id ? errors.country_id : null} 
                label="Country"
                onChange={(value) => {
                    setData({...data, country_id: value})}} 
                options={countries}
                value={data.country_id ?? ''} 
            /> 


            <BTDButton
                formButton={true}
                label="Continue Checkout &#8594;"
                onClick={async () => {

                    try {
                        await updateBasket(data);

                        const response = await axios.get('https://api.events.oakora.com/baskets/' + basketUuid + '/convert')
                            .then(function (response) {

                                if (response.status >= 200 && response.status < 300) {

                                    navigate('/checkout/complete')
                                }
                                else {
                                    showSnackbar(response.data);
                                }
                            }
                            );
                    } catch (error) {
                        showSnackbar(error.response.data.error, 'error');
                    }
                    
                }}
                style={{
                    borderRadius: '28rem',
                    margin: '80px auto 40px auto',
                    padding: '18px 35px',
                }}
            />

        </div>
    );
}

export default SummaryScreen;