// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// other
import './styles.css'
import Container from 'components/BTDUI/Container';

const MicrositeVenue = ({ address, image_upload, name, website }) => {

    const [addressArray, setAddressArray] = useState([]);

    useEffect(() => {

		if(address) {
			setAddressArray(address.split("\n"))
		}
	}, [address])


    return(
        <section className="MS_Section_Venue">

            <Container>


                <div className='MS_Section_Venue__container'>

                    <div className='MS_Section_Venue__container__column'>

                        <div className='MS_Section_Venue__container__column__image' style={{backgroundImage: `url(${image_upload ? image_upload.url: ''})`}}/>
                    </div>

                    <div className='MS_Section_Venue__container__column'>

                        <p className='MS_Section_Venue__container__column__text medium white'>Venue</p>
                        <h2 className='MS_Section_Venue__container__column__name white'>{name ?? ''}</h2>
                        <div className='MS_Section_Venue__container__column__address white'>
                        {addressArray.length !== 0 ?

                            addressArray.map(line => {
                                return(
                                    <p className='medium white' key={line}>{line }</p>
                                )
                            })
                        :
                        '' 
                        }
                        </div>

                        <a href={address ? 'https://www.google.com/maps/place/' + encodeURIComponent(address) : ''} className='MS_Section_Venue__container__column__link pink' target='_blank' rel="noreferrer">Map</a>
                        <a href={website ?? ''} className='MS_Section_Venue__container__column__link pink' target='_blank' rel="noreferrer">Website</a>

                    </div>

                </div>

            </Container>

        </section>
    )
}

MicrositeVenue.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
}

MicrositeVenue.defaultProps = {
    items: [],
    title: '',
}

export default MicrositeVenue;