// Packages
import { useState } from 'react';

// other
import './styles.css';

const BTDNumberButtonField = props => {

    const { onChange } = props;

    const [quantity, setQuantity ] = useState(0);

    return (
        <div className="BTDNumberButtonField">

            <div className="BTDNumberButtonField__section">

                <span 
                    className="BTDNumberButtonField__section__button" 
                    onClick={() => {
                        onChange(quantity - 1);
                        setQuantity(quantity - 1)
                    }}
                >-</span>

            </div>

                <input 
                    className="BTDNumberButtonField__input" 
                    type="number" 
                    value={quantity} 
                    onChange={(e) => {
                        onChange(parseInt(e.target.value));
                        setQuantity(e.target.value);
                    }} 
                />

            <div className="BTDNumberButtonField__section">

                <span 
                    className="BTDNumberButtonField__section__button" 
                    onClick={() => {
                        onChange(quantity + 1);
                        setQuantity(quantity + 1)
                        }}
                >+</span>

            </div>

        </div>
    );
}

export default BTDNumberButtonField;
