// Packages
import PropTypes from 'prop-types';

const CollectionIcon = ({ 
                    colour = "#fff", 
                    filter = false,
                    height = "25px", 
                    onClick = () => {}, 
                    styles = {}, 
                    width =  "25px"
                }) => {
    
    return(
        <div onClick={onClick} style={{display: 'inline-block', ...styles}}>
            <svg height={height} width={width} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    {filter &&
                        <filter x="-82.5%" y="-82.5%" width="265.0%" height="265.0%" filterUnits="objectBoundingBox" id="collectionFilter">
                            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                            <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter>
                    }
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(-1121 -521)" fill={colour} fillRule="nonzero">
                        <g transform="translate(1121 521)" filter="url(#collectionFilter)">
                            <path id="Path" d="m8.3625 0h-7.6023c-0.41988 0-0.76025 0.34037-0.76025 0.76025v7.6023c0 0.41988 0.34037 0.76025 0.76025 0.76025h7.6023c0.41988 0 0.76025-0.34037 0.76025-0.76025v-7.6023c0-0.41988-0.34041-0.76025-0.76029-0.76025z"/>
                            <path id="Path" d="m19.24 0h-7.6023c-0.41988 0-0.76024 0.34037-0.76024 0.76025v7.6023c0 0.41988 0.34037 0.76025 0.76024 0.76025h7.6023c0.41988 0 0.76025-0.34037 0.76025-0.76025v-7.6023c0-0.41988-0.34041-0.76025-0.76025-0.76025z"/>
                            <path id="a" d="m8.3625 10.877h-7.6023c-0.41988 0-0.76025 0.34037-0.76025 0.76024v7.6023c0 0.41988 0.34037 0.76025 0.76025 0.76025h7.6023c0.41988 0 0.76025-0.34037 0.76025-0.76025v-7.6023c0-0.41988-0.34041-0.76024-0.76029-0.76024z"/>
                            <path d="m19.24 10.877h-7.6023c-0.41988 0-0.76024 0.34037-0.76024 0.76024v7.6023c0 0.41988 0.34037 0.76025 0.76024 0.76025h7.6023c0.41988 0 0.76025-0.34037 0.76025-0.76025v-7.6023c0-0.41988-0.34041-0.76024-0.76025-0.76024z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

CollectionIcon.propTypes = {
    colour: PropTypes.string,
    filter: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    styles: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CollectionIcon;