// Packages
import PropTypes from 'prop-types';

// other
import './styles.css'

const MicrositeImage = props => {

    const { image_upload, title, height } = props;

    return(

        <section className="MS-Section-Image" style={{height: `${height}px`, backgroundImage: `url('${image_upload ? image_upload.url : ''}')`}}> 

            <div className="MS-Section-Image__title">{ title }</div>

        </section>
    )
}

MicrositeImage.propTypes = {
    image_upload: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
}

MicrositeImage.defaultProps = {
    image_upload: {},
    height: 200,
    title: '',
}

export default MicrositeImage;