// Packages
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';
import BTDNumberButtonField from 'components/BTDUI/fields/BTDNumberButtonField';

// Other
import './styles.css'
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

const TicketListItem = props => {

	const { id, isLoading, name, onChange, price, quantity } = props;

	const { currency } = useAppData();

	return (
		<div className="TicketListItem">

			<div className='TicketListItem__section'>
				
				<h3 className='TicketListItem__name black'>{! isLoading ? name : <BTDSkeleton />}</h3>

			</div>

			<p className='black centre semi-bold'>{! isLoading ? currency(price) + ' each': <BTDSkeleton /> }</p>

			<BTDNumberButtonField onChange={(quantity) => onChange({ticket_type_id: id, quantity: quantity})} quantity={quantity} />
		</div>
	)
}

TicketListItem.propTypes = {
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	price: PropTypes.string,
	quantity: PropTypes.number,
}

TicketListItem.defaultProps = {
	available: null,
	capacity: null,
	isLoading: false,
	sold: null,
	start_at: '',
	venue: {},
	name: null
}

export default TicketListItem