// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

// other
import './styles.css'
import Container from 'components/BTDUI/Container';
import FormTemplate from 'components/BTDUI/FormTemplate';

const MicrositeSpeakers = ({ items, title, }) => {

    const [selectedItem, setSelectedItem] = useState();
    const [formActive, setFormActive] = useState(false);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                }
            },
            {
                breakpoint: 900,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                }
            },
        ]
    };

    return(
        <>

        <section className="MS_Section_Speakers">

            <Container>

                <h2 className="MS_Section_Speakers__title white centre">{title ? title : 'Speakers'}</h2>

                <div className="MS_Section_Speakers__slider">
                <Slider {...settings}>

                    {items.map((item) => {
                        return(

                            <div key={item.id} className='MS_Section_Speakers__items__item'>
                                <div className='MS_Section_Speakers__items__item__image' style={{backgroundImage: `url(${item.image_upload ? item.image_upload.url: ''})`}}/>
                                <h3 className='MS_Section_Speakers__items__item__name white centre'>{item.name ?? ''}</h3>
                                <p className='MS_Section_Speakers__items__item__text white centre'>{item.role ?? ''} </p>
                                <p className='MS_Section_Speakers__items__item__link pink centre' onClick={() => {
                                    setSelectedItem(item);
                                    setFormActive(true);
                                }}>View Details</p>
                            </div>
                        )
                    })}

                </Slider>

                </div>

            </Container>

        </section>

        {formActive &&
            <FormTemplate onClose={() => {setFormActive(false)}}>

                <div className='MS_Section_Speakers__modal'>
                    <div className='MS_Section_Speakers__modal__column'>
                        <div className='MS_Section_Speakers__modal__column__image' style={{backgroundImage: `url(${selectedItem.image_upload ? selectedItem.image_upload.url: ''})`}}/>
                        <h2 className='MS_Section_Speakers__modal__column__name medium black'>{selectedItem.name ?? ''}</h2>
                        <p className='MS_Section_Speakers__modal__column__text medium black'>{selectedItem.role ?? ''}</p>
                    </div>
                    <div className='MS_Section_Speakers__modal__column'>
                        <p className='MS_Section_Speakers__modal__column__text medium black'>{selectedItem.biography ?? ''}</p>
                    </div>
                </div>
            </FormTemplate>    
        }

        </>
    )
}

MicrositeSpeakers.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
}

MicrositeSpeakers.defaultProps = {
    items: [],
    title: '',
}

export default MicrositeSpeakers;