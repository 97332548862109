// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import TokenScreen from "../screens/token"

const AuthRoutes = () => 
{
	return (
		<Routes>
			<Route path="/token" element={<TokenScreen />}/>
		</Routes>
	)
}

export default AuthRoutes;