// Packages
import ListItem from 'components/Ticket/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const TicketList = props => {

	const { isLoading, items, onChange } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='TicketList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No tickets to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='TicketList'>

			{items.map((item) => {

				return (
					<div key={item.id}>
						<ListItem {...item} isLoading={false} onChange={onChange}/>
					</div>
				)
				
			})}
	
		</div>
	)
}

TicketList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func
}

TicketList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {alert('onChange is not set!')}
}

export default TicketList;