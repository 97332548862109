// Components
import Header from 'components/Header';
import Footer from 'components/Footer';
import MainRoutes from 'routes/main';

// Assets
import './styles.css'


const DefaultLayout = props => {

	
	return (
		<div className="DefaultLayout">
			
			<Header/>

			<MainRoutes/>

			<Footer/>

		</div>
	)
}


export default DefaultLayout