/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	STAGE: process.env.REACT_APP_STAGE,
	MAPS_API_KEY: 'AIzaSyC5GGBqBsfeqO4WEgH602-5luiBKwCj080',
	EDITOR_API_KEY: 'zk1783nnv3566cb03f9k4ze615o2ofnhtezwz0jobn5c790t',

	// VITE_API_URL: "http://api.events.oakora.local",
	VITE_API_URL: "https://api.events.oakora.com",
	VITE_TOKEN_NAME: "oakora-events-access-token",
	VITE_AUTH_URL: "https://auth.oakora.com",
	VITE_AUTH_API_URL: "https://api.auth.oakora.com",
	VITE_RETURN_NAME: "oakora-events-return",

	API_URL: "https://api.events.oakora.com"
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://api.events.oakora.com"
	},
	

	/*
	Staging Environment Configuration
	*/
	staging: {
		API_URL: "https://api.events.oakora.com"
	},


	/*
	Development Environment Configuration
	*/
	development: {
		
	},


	/*
	Local Environment Configuration
	*/
	local: {
		API_URL: "http://api.events.oakora.local"
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config