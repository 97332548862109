// Packages
import { Link } from 'react-router-dom';

// Context
import { useAppData } from "context/AppData";

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import CollectionIcon from 'components/BTDUI/icons/Collection';
import Container from 'components/BTDUI/Container';

// other
import './styles.css';
import BTDButton from 'components/BTDUI/BTDButton';
import UserIcon from 'components/BTDUI/icons/User';
import AdminIcon from 'components/BTDUI/icons/Admin';
import LogoutIcon from 'components/BTDUI/icons/LogoutIcon';

const Header = () => {

	const { apps, auth_settings, user } = useAppData()

	return (
		<header className="Header">
			<div className='Header__top'>

				<Container>

					<div className='Header__top__left'>
						<div className='Header__top__left__logo'>
							<img src={auth_settings?.tenantbar_logo_url} alt='Logo'/>
						</div>
						<div className='Header__top__left__apps'>
							{apps && apps.map((app, index) => (
								<Link to={app ? 'https://' + app.tenant.domain : ''} key={index} className='Header__top__left__apps__app white'>{app?.tenant.name}</Link>
							))}
						</div>
						<div className='Header__top__left__apps__mobile'>
							<BTDMenu icon={<CollectionIcon height='22px' width='22px'/>}>
								{apps && apps.map((app, index) => (
									<Link to={app ? 'https://' + app.tenant.domain : ''} key={index} className='Header__top__left__apps__mobile__app black'>{app?.tenant.name}</Link>
								))}
							</BTDMenu>
						</div>
					</div>

					<div className='Header__top__right'>
						<div className='Header__top__right__name'>
							{user?.firstname + ' ' + user?.lastname}
						</div>
						<BTDMenu arrow={true} icon={<UserIcon colour='#fff' height='27px' styles={{cursor: 'pointer', marginLeft: '0px'}} width='25px'/>} styles={{zIndex: '999'}}>
							<Link className='Header__top__menu black' to={''} target='_blank'><AdminIcon colour='#5E5E5E' height='27px' styles={{marginRight: '5px', transform: 'translateX(-1px)'}} width='27px'/>Admin</Link>
							<Link className='Header__top__menu black' to={''} target='_blank'><UserIcon colour='#5E5E5E' height='27px' styles={{marginRight: '8px'}} width='25px'/>Profile</Link>
							<BTDButton
								label='Sign Out'
								onClick={() => {}}
								startIcon={<LogoutIcon colour='#fff' height='22px' styles={{marginRight: '8px'}} width='22px' />}
								style={{margin: '0 auto', paddingTop: '10px', paddingBottom: '10px'}}
							/>
						</BTDMenu>
					</div>

				</Container>

			</div>

			
		</header>

	)

}

export default Header;