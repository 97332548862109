// Packages
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Context
import { useSnackbar } from 'context/Snackbar';
// import { useDialogue } from '@context/Dialogue';

// Other
import config from 'other/config';

const useApi = (url, runGet=true ) => {

    const { showSnackbar } = useSnackbar();
    // const { confirm } = useDialogue();

	const [ data, setData ] = useState([])
	const [ meta, setMeta ] = useState([])
	const [ isError, setIsError ] = useState(true)
	const [ errors, setErrors ] = useState({})
    const [ errorCode, setErrorCode] = useState()
    const [ errorMessage, setErrorMessage] = useState()
	const [ refresh, setRefresh ] = useState(false)
    const [params, setParams] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    /**	
     * Return the access token from local storage
     */
    const getAccessToken = () => {

        if ( localStorage.getItem(config.VITE_TOKEN_NAME) )
        {
            return localStorage.getItem(config.VITE_TOKEN_NAME)
        }
        else
        {
            // No access token. go to auth
            redirectToAuth()
        }
    }

    /**
     * Error handler
     */
    useEffect(() => {

        if ( errorCode === 401 )
        {
            redirectToAuth()
        }

    }, [errorCode])

    /**
     * Redirect to Oakora Auth
     */
    const redirectToAuth = () => {

        localStorage.setItem(config.VITE_RETURN_NAME, window.location.href)

        let returnDomain = window.location.protocol + '//' + window.location.hostname;

        if ( window.location.port )
        {
            returnDomain += ':' + window.location.port
        }

        window.location = config.VITE_AUTH_URL + '/auth/app?return=' + returnDomain;
    }



    const request = async ( method, url, postData, successMessage = null, redirect = null ) => {

        setIsLoading(true);

            try {
                
                const response = await axios.request({
                    baseURL: url.substring(0,4) == 'http' ? '' : config.VITE_API_URL,
                    url: url + params,
                    method,
                    responseType: 'json',
                    data: postData,
                    headers: {
                        Authorization: 'Bearer ' + getAccessToken()
                    }
                })
                    
                setData(response.data.data);
                setMeta(response.data.meta);
                setIsError(false);
                setIsLoading(false);
                if(successMessage) {
                    showSnackbar(successMessage, 'success');
                }

                if( redirect ) {

                    navigate(redirect + response.data.data.id);
                }

                return response.data.data;
                // return true;

            } catch (error) {
                
                setIsError(true);
                setErrors(error.response.data.errors);
                setErrorCode(error.response.status)
                setErrorMessage(error.response.data.message)
                showSnackbar(error.response.data.message, 'error');

                throw new Error(error);
                
            }
        
    }

	useEffect(() => 
	{
        if( runGet ) {

            request('get', url);
        }
    
    // eslint-disable-next-line
	}, [url, refresh, params])


    const get = async (successMessage) => 
	{
        return await request('get', url, undefined, successMessage);
    };

	const put = async (postData, successMessage, redirect) => 
	{
        return await request('put', url, postData, successMessage, redirect)
    };

    const post = async (postData, successMessage, redirect, params = '') => 
    {
        return await request('post', url + params, postData, successMessage, redirect);
    };

    const destroy = async (itemType, successMessage) => 
    {
        // if( ! await confirm('Are you sure that you want to remove this ' + itemType + '?', 'Yes, delete it', 'No, leave it there') ) {
            
        //     return false;
        // }
        // else {

            return await request('delete', url, undefined, successMessage)
        // }

    };

    const removeItem = async (id) => {

        return await request('delete', url + '/' + id);
    }

    const onChange = useCallback(() => {
        setRefresh(prevRefresh => !prevRefresh);
    }, []);

	return { 
        data,
        meta,
		isLoading, 
		isError, 
        errors,
        errorMessage,
        get,
		put, 
		post,
        destroy,
        removeItem,
        onChange,
        setParams
	}
}

export default useApi;