// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const PersonIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="PersonIcon" onClick={onClick} style={style}>
            <svg width={width} height={height} viewBox="0 0 25 25" version="1.1">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Header" transform="translate(-101, -64)" fill={colour} fillRule="nonzero">
                        <g id="profile-user" transform="translate(101, 64)">
                            <path d="M12.5,0 C5.59716244,0 0,5.59606431 0,12.4994509 C0,19.4028376 5.59661337,24.9989019 12.5,24.9989019 C19.4039357,24.9989019 25,19.4028376 25,12.4994509 C25,5.59606431 19.4039357,0 12.5,0 Z M12.5,3.73748133 C14.7841079,3.73748133 16.635004,5.58892647 16.635004,7.87193622 C16.635004,10.155495 14.7841079,12.0063911 12.5,12.0063911 C10.2169902,12.0063911 8.36609418,10.155495 8.36609418,7.87193622 C8.36609418,5.58892647 10.2169902,3.73748133 12.5,3.73748133 Z M12.4972547,21.7308706 C10.2191865,21.7308706 8.13274181,20.9012343 6.52343407,19.5280242 C6.13140209,19.193644 5.90518756,18.7033295 5.90518756,18.1888562 C5.90518756,15.8734516 7.77914434,14.0203593 10.095098,14.0203593 L14.9060002,14.0203593 C17.2225029,14.0203593 19.0893218,15.8734516 19.0893218,18.1888562 C19.0893218,18.7038786 18.8642054,19.193095 18.4716244,19.5274752 C16.8628657,20.9012343 14.7758719,21.7308706 12.4972547,21.7308706 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

PersonIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PersonIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default PersonIcon;