// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// other
import './styles.css'

const MicrositeCountdown = props => {

    const { start_date, show_hours, show_minutes, show_seconds, title } = props;

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {

        const now = new Date().getTime();
        const targetDate = new Date(start_date).getTime();
        const difference = targetDate - now;

        if (difference <= 0) {

            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }

    useEffect(() => {

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);

    }, [start_date]);

    return (
        <section className="MS-Section-Countdown">

            <h2 className="MS-Section-Countdown__title">{title ?? ''}</h2> 

            <div className='MS-Section-Countdown__content'>

                {timeLeft.days > 0 && <span className="MS-Section-Countdown__content__number">{timeLeft.days} days </span>}
                {show_hours &&  <span className="MS-Section-Countdown__content__number">{timeLeft.hours} hours </span>}
                {show_minutes && <span className="MS-Section-Countdown__content__number">{timeLeft.minutes} minutes </span>}
                {show_seconds && <span className="MS-Section-Countdown__content__number">{timeLeft.seconds} seconds </span>}

            </div>

        </section>
    );
}

MicrositeCountdown.propTypes = {
    start_date: PropTypes.string,
    show_hours: PropTypes.bool,
    show_minutes: PropTypes.bool,
    show_seconds: PropTypes.bool,
    title: PropTypes.string
}

MicrositeCountdown.defaultProps = {
    start_date: '',
    show_hours: true,
    show_minutes: true,
    show_seconds: true,
    title: ''
}

export default MicrositeCountdown;
