// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css'
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';

const GuestListItem = ({ index, isLoading, onChange, ticket_type, uuid }) => {

	const [value, setValue] = useState();

	return (
		<div className="GuestListItem">

			<div className='GuestListItem__header'>
				<h3 className=''>{! isLoading ? ticket_type ? 'Ticket #' + index + ' - ' + ticket_type.name : '' : <BTDSkeleton />}</h3>
			</div>

			<div className='GuestListItem__main'>
				
				<BTDTextField
					label={'Guest name #' + index}
					onChange={(value) => {
						setValue(value);
						onChange({[uuid]: {guest_name: value}})
					}}
					style={{backgroundColor: '#fff'}}
					value={value ?? ''}
					width='large'
				/>

			</div>

		</div>
	)
}

GuestListItem.propTypes = {
	index: PropTypes.number,
	isLoading: PropTypes.bool,
	onChange: PropTypes.func,
	ticket_type: PropTypes.object,
	uuid: PropTypes.string,
}

GuestListItem.defaultProps = {
	index: PropTypes.number,
	isLoading: false,
	onChange: () => {alert('onChange is not set!')},
	ticket_type: {},
	uuid: ''
}

export default GuestListItem;