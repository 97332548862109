// Packages
import { Link } from 'react-router-dom';
import ListItem from 'components/Event/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const EventList = props => {

	const { isLoading, items } = props;

	const urls = [
		'https://oakora-events-api.s3.eu-west-2.amazonaws.com/seeder/anthony-delanoix-hzgs56Ze49s-unsplash.jpg',
		'https://oakora-events-api.s3.eu-west-2.amazonaws.com/seeder/getty-images-vDmf4My-4k0-unsplash.jpg',
		'https://oakora-events-api.s3.eu-west-2.amazonaws.com/seeder/ian-schneider-PAykYb-8Er8-unsplash.jpg',
		'https://oakora-events-api.s3.eu-west-2.amazonaws.com/seeder/getty-images-XVkUW0-fsfw-unsplash.jpg',
		'https://oakora-events-api.s3.eu-west-2.amazonaws.com/seeder/jakob-dalbjorn-cuKJre3nyYc-unsplash.jpg',
		'https://oakora-events-api.s3.eu-west-2.amazonaws.com/seeder/getty-images-MgvEHXxGrUs-unsplash.jpg',
	]


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='EventList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No events to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='EventList'>

			{items.map((item, index) => {

				return (
						<Link key={item.id} to={`/s/${item.id}/${item.microsite.slug}`}>
							<ListItem {...item} isLoading={false} image_url={urls[index]}/>
						</Link>
				)
				
			})}
	
		</div>
	)
}

EventList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

EventList.defaultProps = {
	isLoading: false,
	items: [],
}

export default EventList;