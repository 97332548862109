// Packages
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import BasketTimer from "components/BTDUI/BasketTimer";
import BTDProgress from "components/BTDUI/BTDProgress";
import GuestList from "components/Guest/List";
import BTDButton from "components/BTDUI/BTDButton";

// Context
import { useAppData } from "context/AppData";
import { useBasket } from "context/Basket";
import { useNav } from "context/Nav";
import { useUI } from "context/UI";

// other
import './styles.css';
import useApi from "hooks/useApi";

const GuestsScreen = () => {

    const { settings, user } = useAppData();
    const { basket, hasBasket, getBasketItems } = useBasket();
    const { setUnsavedChanges } = useNav();
    const { getLoading } = useUI();

    const navigate = useNavigate();

    const [basketUuid, setBasketUuid] = useState();
    const [values, setValues] = useState([]);

    const { data, put: updateBasket } = useApi('/baskets/' + basketUuid + '/tickets', false);


    useEffect(() => {

        const checkBasket = async () => {
    
            if ( ! await hasBasket()) {

                navigate('/');

            } else {

                setUnsavedChanges(true);
                
                if( basket ) {
                    
                    setBasketUuid(basket.uuid);
                }
            }
        };
    
        checkBasket();

    // eslint-disable-next-line
    }, [hasBasket]);

        
    if( getLoading() ) {

        return(
            <BTDProgress type="circular" width={100} style={{margin: '100px auto'}} />
            )
    }
    

    return (
        <div className="GuestsScreen">

            <BasketTimer />

            <div className="GuestsScreen__logo">
                <img src={settings.image_upload ? settings.image_upload.url : user.avatar_url} alt="Logo" />
            </div>

            <h1 className="GuestsScreen__title semi-bold">Tell us about your guests</h1>

            {getBasketItems() &&
                
                <GuestList items={getBasketItems()} onChange={(newValue) => {

                        let newValues = [];
                        for(let i = 0; i < values.length; i++ ) {

                            if( Object.keys(newValue)[0] !== Object.keys(values[i])[0] ) {

                                newValues.push(values[i]);
                            }
                        }
                        newValues.push(newValue);

                    setValues(newValues);
                }}/>
            }

            <BTDButton
                label="Continue Checkout &#8594;"
                onClick={() => {

                    const valuesObject = values.reduce((result, obj) => {
                        const key = Object.keys(obj)[0]; 
                        result[key] = obj[key];
                        return result;
                    }, {});
                    
                    updateBasket({tickets: {...valuesObject}});
                }}
                style={{
                    borderRadius: '28rem',
                    margin: '0 auto 40px auto',
                    padding: '18px 35px',
                }}
            />

        </div>
    );
}

export default GuestsScreen;