// other
import './styles.css';

const ThankYouScreen = () => {

    return(
        <div className='ThankYouScreen'>

            <h1 className='centre'>Thank you - your order has been placed</h1>
        </div>
    )
}

export default ThankYouScreen;