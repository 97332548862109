// Packages
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { useNav } from 'context/Nav';

const UnsavedChangesPrompt = props => {

    const { unsavedChanges } = useNav();
    const navigate = useNavigate();

    useEffect(() => {

        const handleBeforeUnload = (event) => {

            if (unsavedChanges) {

                event.preventDefault();
                event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, [unsavedChanges, navigate]);

    return (
        <>
        {props.children}
        </>
    );
}

export default UnsavedChangesPrompt;

