// Context
import { useAppData } from 'context/AppData';

import './styles.css';
import Container from 'components/BTDUI/Container';

const Footer = () => {

	const { appdata } = useAppData();

	return (
		<footer className="Footer">
			<Container>

				<div>{new Date().getFullYear()} &copy; {appdata ? appdata.name : ''}</div>

				<div>
					<p>Powered by</p>
					<div className='Footer__logo' />
				</div>

			</Container>
		</footer>
	)
}

export default Footer;