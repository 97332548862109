// Packages
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { useSnackbar } from 'context/Snackbar'
import { useUI } from 'context/UI';

// Other
import axios from 'axios';

const BasketContext = createContext();

export function BasketProvider({ children }) {

    const { showSnackbar } = useSnackbar();
    const { setLoading } = useUI();
    const navigate = useNavigate();

    const [basket, setBasket] = useState();

    const getCookie = (cookieName) => {

        const cookies = document.cookie.split("; ");

        for (const cookie of cookies) {

            const [name, value] = cookie.split("=");

            if (name === cookieName) {
                return value;
            }
        }
        return null;
    }

    const hasBasket = async () => {

        if ( basket || document.cookie.indexOf('basket_uuid=') !== -1 ) {

            if( basket == null ) {

                setLoading(true);

                const uuid = getCookie('basket_uuid');
        
                try {
                    const response = await axios.get('https://api.events.oakora.com/baskets/' + uuid);

                    if( response.data.data.has_expired ) {
                        navigate('/');
                        showSnackbar('Basket has expired');
                    }
                    setBasket(response.data.data);
                    setLoading(false);
                } catch (error) {
                    showSnackbar(error.message, 'error');
                    setLoading(false);
                    return false;
                }
            }

            return true;
        }
        else {

            return false;
        }
    }

    const getBasketItems = () => {

        return basket ? basket.items : null;
    }

    const getGroupedBasketItems = () => {

        if( basket ) {

            const groupedItems = basket.items.reduce((grouped, current) => {

                if ( ! grouped[current.ticket_type.name] ) {

                    grouped[current.ticket_type.name] = [];
                }
                
                grouped[current.ticket_type.name].push(current);
                
                return grouped;
            }, {});

            const groupedItemsWithTotal = Object.keys(groupedItems).map(ticket_type => {
                const group = groupedItems[ticket_type];
                const total = group.reduce((sum, obj) => sum + parseFloat(obj.ticket_type.price), 0);
                return {
                    name: ticket_type,
                    // items: group,
                    quantity: group.length,
                    total,
                };
            });

            return groupedItemsWithTotal;

        }
        else {

            return null;
        }

    }

    const getBasketTotal = () => {

        if( basket ) {

            var total = 0;
            for( let i = 0; i < basket.items.length; i++ ) {
                total += parseFloat(basket.items[i].ticket_type.price);
            }

            return total;
        }
        else {
            return null;
        }
    }

    const getBasketUuid = () => {

        return basket ? basket.uuid : null;
    }

    const getBasketExpiry = () => {

        return basket ? basket.expires_at : null;
    }

    return (
        <BasketContext.Provider 
            value={{ 
                basket,
                setBasket,
                hasBasket,
                getBasketItems,
                getBasketUuid,
                getBasketExpiry,
                getGroupedBasketItems,
                getBasketTotal
            }}
        >
            {children}
        </BasketContext.Provider>
    );
}

export function useBasket() {

    return useContext(BasketContext);
}