// Packages
import { Routes, Route } from "react-router-dom"

// Components
import UnsavedChangesPrompt from 'components/BTDUI/UnsavedChangesPrompt';

// Screens
import EventsListScreen from "screens/Events/List"
import EventViewScreen from "screens/Events/View"
import GuestsScreen from "screens/Guests"
import TicketsScreen from "screens/Events/Tickets"
import SummaryScreen from "screens/Checkout/Summary"
import ThankYouScreen from "screens/Checkout/ThankYou";
import AuthRoutes from "./auth";

const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/checkout/customer/*" element={<UnsavedChangesPrompt><SummaryScreen/></UnsavedChangesPrompt>}/>
			<Route path="/checkout/complete/*" element={<ThankYouScreen/>}/>
			<Route path="/guests/*" element={<UnsavedChangesPrompt><GuestsScreen/></UnsavedChangesPrompt>}/>
			<Route path="/events/*" element={<EventsListScreen />}/>
			<Route path="/s/:eventId/*" element={<EventViewScreen/>}/>
			<Route path="/s/:eventId/:slug/tickets/*" element={<TicketsScreen/>}/>
			<Route path="/auth/*" element={<AuthRoutes />} />
			<Route path="/*" element={<EventsListScreen />}/>
		</Routes>
	)
}


export default MainRoutes