// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// context
import { useBasket } from 'context/Basket';
import { useSnackbar } from 'context/Snackbar';

// other
import './styles.css';

function BasketTimer() {

    const { getBasketExpiry } = useBasket();
    const { showSnackbar } = useSnackbar();
    const [timeRemaining, setTimeRemaining] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        if (getBasketExpiry()) {

            const interval = setInterval(() => {
                const now = new Date().getTime();
                const expiryTime = new Date(getBasketExpiry());
                const remainingTime = expiryTime.getTime() - now;

                if (remainingTime <= 0) {

                    clearInterval(interval);
                    setTimeRemaining('0m 0s');
                    navigate('/');
                    showSnackbar('Basket has expired');

                } else {
                    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                    setTimeRemaining(`${minutes}m ${seconds}s`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [getBasketExpiry]);


    return (
        <div className='BasketTimer'>

            {timeRemaining !== null ? (
                <>
                    <p>You have</p>
                    <div className='BasketTimer__time semi-bold'>{timeRemaining}</div>
                    <p className='black'>to complete your order</p>
                </>
            ) : (
                <p></p>
            )}

        </div>
    );
}

export default BasketTimer;