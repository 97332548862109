// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDTextField = ({ description, endIcon, error, label, onChange, placeholder, required, startIcon, style, value, width }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if( value != null && value != '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <>
        {description &&
            <p className='BTDTextField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDTextField ${width} `}>
            <label className={`BTDTextField__label ${active ? 'active' : ''} ${startIcon ? 'startIcon' : ''}`}>{label ?? ''}
                {required &&
                    <span className='red'> *</span>
                }

            </label>

            <div className="BTDTextField__startIcon">
                {startIcon}
            </div>

            <input 
                className={`BTDTextField__input ${error ?? ''} ${startIcon ? 'startIcon' : ''}`} 
                onChange={(e) => {onChange(e.target.value)}} 
                placeholder={placeholder}
                required={required} 
                style={style}
                value={value} 
            />
            {endIcon &&
                <div className='BTDTextField__endIcon'/>
            }
            {error &&
                <p className='BTDTextField__error'>{error}</p>
            }
        </div>
        </>
    )
}

BTDTextField.propTypes = {
    description: PropTypes.string,
    endIcon: PropTypes.object,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    startIcon: PropTypes.object,
    style: PropTypes.object,
    value: PropTypes.string,
    width: PropTypes.string
}

BTDTextField.defaultProps = {
    description: null,
    endIcon: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    startIcon: null,
    style: {},
    value: '',
    width: 'large'
}

export default BTDTextField;                                         