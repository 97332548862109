import config from 'other/config'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const TokenScreen = () => {

	const [searchParams] = useSearchParams()

	useEffect(() => {

		const token = searchParams.get('_token')
		const returnUrl = localStorage.getItem(config.VITE_RETURN_NAME)

		if ( token && returnUrl )
		{
			localStorage.setItem(config.VITE_TOKEN_NAME, token)
			localStorage.removeItem(config.VITE_RETURN_NAME)

			window.location = returnUrl
		}

	}, [searchParams])


	return (<>x</>)
}

export default TokenScreen