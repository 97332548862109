// Packages
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

// Components
import TicketList from "components/Ticket/List";
import BTDButton from "components/BTDUI/BTDButton";

// Context
import { useAppData } from "context/AppData";
import { useBasket } from "context/Basket";
import { useSnackbar } from "context/Snackbar";

// other
import './styles.css'
import useApi from 'hooks/useApi';

const TicketsScreen = () => {

    const { eventId } = useParams();

    const { currency, settings, user } = useAppData();
    const { setBasket, setBasketUuid } = useBasket();
    const { showSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const { data: createdBasket, post: createBasket} = useApi('/baskets', false);
    const { data: ticketTypes, isLoading } = useApi('/events/' + eventId + '/tickettypes ');

    const [total, setTotal] = useState(0);
    const [tickets, setTickets] = useState([]);


    const calculateTotal = (item) => {

        let newArray = []
        let currentTotal = 0;

        // Add price of item to total
        for( let i = 0; i < ticketTypes.length; i++ ) {

            if( ticketTypes[i].id === item.ticket_type_id ) {

                currentTotal += ticketTypes[i].price * item.quantity;
            }
        }
        

        // Add all existing basket items (excluding item ticketType) to newArray
        for( let i = 0; i < tickets.length; i++ ) {

            if( item.ticket_type_id !== tickets[i].ticket_type_id ) {
                
                for( let j = 0; j < ticketTypes.length; j++ ) {
                    
                    if(tickets[i].ticket_type_id === ticketTypes[j].id) {
                        
                        newArray.push(tickets[i])
                        currentTotal += ticketTypes[j].price * tickets[i].quantity
                    }
                    
                }
            }
        }

        newArray.push(item);

        setTickets(newArray);
        setTotal(currentTotal);
    }

    const handleBasket = async () => {

        const ticketType = tickets.reduce((result, item) => {
            result[item.ticket_type_id] = item.quantity;
            return result;
        }, {});

        try {

            const response = await createBasket({ticket_type: ticketType});

            setBasket(response);

            const cookieName = "basket_uuid";
            const cookieValue = response.uuid;
            const expirationTime = new Date(response.expires_at);
            expirationTime.getTime();
            const cookieOptions = `expires=${expirationTime.toUTCString()}; path=/`;

            document.cookie = `${cookieName}=${cookieValue}; ${cookieOptions}`;

            navigate('/checkout/customer');
        }
        catch (error) {

            showSnackbar(error.message, 'error');
            return false;
        }


    }

    return(
        <div className="TicketsScreen">

            <div className="TicketsScreen__logo">
            </div>

            <h1 className="TicketsScreen__title semi-bold">Select your tickets</h1>

                <TicketList items={ticketTypes} isLoading={isLoading} onChange={calculateTotal} />

            <div className="TicketsScreen__total">

                <span className="TicketsScreen__total__label black">Total</span>

                <span className="TicketsScreen__total__figure black semi-bold">{total}</span>
            </div>
            
            <BTDButton
                disabled={total === 0}
                label="Add tickets to basket &#8594;"
                onClick={handleBasket}
                style={{
                    borderRadius: '28rem',
                    margin: '0 auto 40px auto',
                    padding: '18px 35px',
                }}
            />
        </div>
    )
}

export default TicketsScreen;