// Packages
import PropTypes from 'prop-types';

// Components
import MicrositeHeader from '../Header';
import MicrositeImage from '../Image';
import MicrositeText from '../Text';
import MicrositeCountdown from '../Countdown';
import MicrositeAccommodation from '../Accommodation';
import MicrositeVenue from '../Venue';
import MicrositeSpeakers from '../Speakers';

// other
import './styles.css';

const MicrositeSectionListItem = ({ eventId, isLoading, section, slug, start_date }) => {

    return(
        <div key={section.id} className="MicrositeSectionListItem">

            {! isLoading && section.template &&

                <div className="MicrositeSectionListItem__content" id={'section' + section.id}>
                    {section.template.name === 'Header' &&
                        <MicrositeHeader {...section.config} eventId={eventId} navigation={section.additional_data ?? []} slug={slug} />
                    }
                    {section.template.name === 'Image' &&
                        <MicrositeImage {...section.config} />
                    }
                    {section.template.name === 'Text' &&
                        <MicrositeText {...section.config} />
                    }
                    {section.template.name === 'Countdown' &&
                        <MicrositeCountdown {...section.config} start_date={start_date} />
                    }
                    {section.template.name === 'Accommodation' &&
                        <MicrositeAccommodation {...section.config} items={section.additional_data ?? []} />
                    }
                    {section.template.name === 'Speakers' &&
                        <MicrositeSpeakers {...section.config} items={section.additional_data ?? []} />
                    }
                    {section.template.name === 'Venue' &&
                        <MicrositeVenue {...section.config} {...section.additional_data} />
                    }
                </div>

            }

        </div>
    )
}

MicrositeSectionListItem.propTypes = {
    eventId: PropTypes.string,
    isLoading: PropTypes.bool,
    section: PropTypes.object,
    slug: PropTypes.string,
    start_date: PropTypes.string
}

MicrositeSectionListItem.defaultProps = {
    eventId: null,
    isLoading: false,
    section: {},
    slug: '',
    start_date: ''
}

export default MicrositeSectionListItem;